import { Timestamp } from 'firebase/firestore';
import moment from 'moment';

// Adjust the formatDate function to handle both Timestamp and string inputs
export const formatDate = (dateInput: Timestamp | string) => {
  let date: Date;

  // Check if dateInput is a Firestore Timestamp object and convert it
  if (dateInput instanceof Timestamp) {
    date = dateInput.toDate();
  } else if (typeof dateInput === 'string') {
    // Parse the date string to a Date object
    date = new Date(dateInput);
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the date is invalid
    }
  } else {
    // If the input is neither a Timestamp nor a string, return an empty string or throw an error
    return '';
  }

  // Format the date using moment.js
  return moment(date).format('D MMMM YYYY'); // Format like "3 May 2024"
};

// Convert Firestore Timestamp to a string in 'YYYY-MM-DD' format for input[date]
export const timestampToDateInputValue = (timestamp: Timestamp): string => {
  const date = timestamp.toDate(); // Convert to JavaScript Date object
  return date.toISOString().split('T')[0]; // Format as 'yyyy-MM-dd'
};