// src/components/ResultsPage.tsx
import React, { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { Scores } from '../types/types';
import '../styles/ResultsPage.css';
import { deleteUser, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config';

const initialScores: Scores = {
  E: 0,
  I: 0,
  S: 0,
  N: 0,
  T: 0,
  F: 0,
  J: 0,
  P: 0,
};

const ResultsPage = () => {
  const [mbtiType, setMbtiType] = useState('');
  const [scores, setScores] = useState<Scores>(initialScores);
  const roundToWholeNumber = (value: number) => Math.round(value);
  const [uiTranslations, setUiTranslations] = useState<string[]>([]);
  const [showResult, setShowResult] = useState<boolean | null>(null);

  useEffect(() => {
    // Fetch UI translations based on the language set in the training session
    const fetchUITranslations = async () => {
      const sessionHashKey = localStorage.getItem('sessionHashKey');
      if (sessionHashKey) {
        const sessionDocRef = doc(db, 'trainingSessions', sessionHashKey);
        const sessionDocSnap = await getDoc(sessionDocRef);
        if (sessionDocSnap.exists()) {
          const sessionData = sessionDocSnap.data();
          const languageCode = sessionData.language;
          if (languageCode && languageCode !== 'en') {
            const translationsRef = doc(db, 'translatedUI', languageCode);
            const translationsSnap = await getDoc(translationsRef);
            if (translationsSnap.exists()) {
              // Assuming the structure is like { result: ['Your MBTI type is', ...] }
              setUiTranslations(translationsSnap.data().result);
            } else {
              console.error(`No UI translations found for language: ${languageCode}`);
            }
          }
        } else {
          console.error('No session document found');
        }
      }
    };
  
    fetchUITranslations();
  }, []);

  useEffect(() => {
    const sessionHashKey = localStorage.getItem('sessionHashKey');
    if (sessionHashKey) {
      const sessionDocRef = doc(db, 'trainingSessions', sessionHashKey);
  
      const unsubscribe = onSnapshot(sessionDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const sessionData = docSnapshot.data();
          // Update state based on the fetched showResult field
          setShowResult(sessionData.showResult);
        } else {
          console.error('No session document found');
        }
      });
  
      // Cleanup the listener when the component unmounts
      return () => unsubscribe();
    }
  }, []);

  const determineMbtiType = (scores: Scores): string => {
    let type = '';
  
    // Compare each dichotomy and lean towards the correct type in case of a tie
    type += scores.E <= scores.I ? 'I' : 'E'; // Lean towards 'I' if E and I are tied
    type += scores.S <= scores.N ? 'N' : 'S'; // Lean towards 'N' if S and N are tied
    type += scores.T <= scores.F ? 'F' : 'T'; // Lean towards 'F' if T and F are tied
    type += scores.J <= scores.P ? 'P' : 'J'; // Lean towards 'P' if J and P are tied
  
    return type;
  };

  useEffect(() => {
    const documentId = localStorage.getItem('documentId'); // Retrieve the document ID from localStorage
  
    const fetchResults = async () => {
      if (documentId) {
        const docRef = doc(db, 'results', documentId);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const data = docSnap.data();
          setScores(data.scores);
  
          // Use the new function to determine the MBTI type
          const determinedType = determineMbtiType(data.scores);
          setMbtiType(determinedType);
        } else {
          console.error('No such document!');
        }
      } else {
        console.error('Document ID not found in localStorage');
      }
    };
  
    fetchResults();
  }, []);

  // Calculate the value for the range inputs based on the scores
  const calculateRangeValue = (scoreType1: number, scoreType2: number) => {
    const total = scoreType1 + scoreType2;
    // Avoid division by zero and ensure the inputs are numbers
    if (total === 0 || isNaN(scoreType1) || isNaN(scoreType2)) {
      return 0; // Or another appropriate default value
    }
    return (scoreType2 / total) * 100;
  };

  const calculatePercentage = (scoreType1: number, scoreType2: number) => {
    const total = scoreType1 + scoreType2;
    if (total === 0 || isNaN(scoreType1) || isNaN(scoreType2)) {
      return [50, 50]; // Split evenly if scores are zero or invalid
    }
    let percent1 = (scoreType1 / total) * 100;
    let percent2 = (scoreType2 / total) * 100;
  
    // Adjust to ensure the sum is 100%
    percent1 = roundToWholeNumber(percent1);
    percent2 = 100 - percent1;
  
    return [percent1, percent2];
  };

  useEffect(() => {
    if (showResult) {
      // This code will run after the component has rendered with showResult being true
      setTimeout(() => {
        localStorage.clear();
      }, 1000);
    }
  }, [showResult]);

  useEffect(() => {
    if (showResult) {
      const handleAccountDeletion = async () => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            deleteUser(user)
              .then(() => {
                console.log('User account deleted successfully.');
                localStorage.clear();
              })
              .catch((error) => {
                console.error('Error deleting user:', error.message);
              });
          } else {
            console.log('No user logged in.');
          }
        });
      };

      setTimeout(() => {
        handleAccountDeletion();
      }, 1000);
    }
  }, [showResult]);

  return (
    <div className="mainContainer" style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', height: '60vh', marginTop: '10%' }}>
      {showResult ? (
        <>
          <div style={{ flex: 1 }}>
            Your MBTI profile type is
            {uiTranslations[0] && <p>{uiTranslations[0]}</p>}
            <h1>{mbtiType}</h1>
          </div>
          <div style={{ flex: 3 }} className="scoresContainer">
            <div className="dichotomyContainer">
              <div className="attributeContainer">
                E<br/>
                Extraversion<br/>
                {/* {uiTranslations[1] && <span className='translatedAttribute'>{uiTranslations[1]}<br/></span>} */}
                <span className="score">{calculatePercentage(scores.E, scores.I)[0]}%</span>
              </div>
              <div className="rangeContainer"><input type="range" min="0" max="100" className="resultRange" value={calculateRangeValue(scores.E, scores.I)} disabled /></div>
              <div className="attributeContainer">
                I<br/>
                Introversion<br/>
                {/* {uiTranslations[2] && <span className='translatedAttribute'>{uiTranslations[2]}<br/></span>} */}
                <span className="score">{calculatePercentage(scores.E, scores.I)[1]}%</span>
                </div>
            </div>
            <div className="dichotomyContainer">
              <div className="attributeContainer">
                S<br/>
                Sensing<br/>
                {/* {uiTranslations[3] && <span className='translatedAttribute'>{uiTranslations[3]}<br/></span>} */}
                <span className="score">{calculatePercentage(scores.S, scores.N)[0]}%</span>
              </div>
              <div className="rangeContainer"><input type="range" min="0" max="100" className="resultRange" value={calculateRangeValue(scores.S, scores.N)} disabled /></div>
              <div className="attributeContainer">
                N<br/>
                Intuition<br/>
                {/* {uiTranslations[4] && <span className='translatedAttribute'>{uiTranslations[4]}<br/></span>} */}
                <span className="score">{calculatePercentage(scores.S, scores.N)[1]}%</span>
              </div>
            </div>
            <div className="dichotomyContainer">
              <div className="attributeContainer">
                T<br/>
                Thinking<br/>
                {/* {uiTranslations[5] && <span className='translatedAttribute'>{uiTranslations[5]}<br/></span>} */}
                <span className="score">{calculatePercentage(scores.T, scores.F)[0]}%</span>
              </div>
              <div className="rangeContainer"><input type="range" min="0" max="100" className="resultRange" value={calculateRangeValue(scores.T, scores.F)} disabled /></div>
              <div className="attributeContainer">
                F<br/>
                Feeling<br/>
                {/* {uiTranslations[6] && <span className='translatedAttribute'>{uiTranslations[6]}<br/></span>} */}
                <span className="score">{calculatePercentage(scores.T, scores.F)[1]}%</span>
              </div>
            </div>
            <div className="dichotomyContainer">
              <div className="attributeContainer">
                J<br/>
                Judging<br/>
                {/* {uiTranslations[7] && <span className='translatedAttribute'>{uiTranslations[7]}<br/></span>} */}
                <span className="score">{calculatePercentage(scores.J, scores.P)[0]}%</span>
              </div>
              <div className="rangeContainer"><input type="range" min="0" max="100" className="resultRange" value={calculateRangeValue(scores.J, scores.P)} disabled /></div>
              <div className="attributeContainer">
                P<br/>
                Perceiving<br/>
                {/* {uiTranslations[8] && <span className='translatedAttribute'>{uiTranslations[8]}<br/></span>} */}
                <span className="score">{calculatePercentage(scores.J, scores.P)[1]}%</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <h1>
            Let's resume the lesson
            {uiTranslations[9] && (
              <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}><br/>{uiTranslations[9]}</span>
            )}
          </h1>
          <p>
            Come back later for your result!
            {uiTranslations[10] && (
              <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}><br/>{uiTranslations[10]}</span>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default ResultsPage;
