import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, onSnapshot, updateDoc, writeBatch, deleteField } from 'firebase/firestore';
import { db } from '../firebase-config'; // Adjust the import path as necessary
import moment from 'moment';
import '../styles/ViewResults.css';
import '../styles/certificate.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faEnvelope, faFilePdf, faMobile, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import ListEnd from './ListEnd';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import BebasNeueRegular from '../fonts/BebasNeue-Regular.ttf';
import OpenSansRegular from '../fonts/OpenSans-Regular.ttf';
import OpenSansBold from '../fonts/OpenSans-Bold.ttf';
import CompanyLogo from '../images/brighton-academy-logo-white.png';
import mbtiCertifiedLogo from '../images/mbti-logo.png';
import { faTimes, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
Font.registerHyphenationCallback(word => [word]);

type MBTIResult = {
  id?: string;
  firstName: string;
  lastName: string;
  mbtiType: string;
  email: string;
  mobilePhone: string;
  scores: Record<string, number>;
  status?: string;
};

type CertificateProps = {
  firstName: string;
  lastName: string;
  mbtiType: string;
  scores: Record<string, number>;
  sessionDate: Date;
};

const StudentResults = () => {
  const { sessionId } = useParams();
  const [sessionDetails, setSessionDetails] = useState<{ name?: string; sessionDate?: Date | null }>({});
  const [mbtiResults, setMbtiResults] = useState<MBTIResult[]>([]); // State to store MBTI results
  const [showCertificate, setShowCertificate] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] = useState<MBTIResult | null>(null);
  const [pdfError, setPdfError] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  useEffect(() => {
    const fetchSessionDetails = async () => {
      if (sessionId) {
        const sessionRef = doc(db, 'trainingSessions', sessionId);
        const sessionSnap = await getDoc(sessionRef);
  
        if (sessionSnap.exists()) {
          const data = sessionSnap.data();
          setSessionDetails({
            name: data.name,
            sessionDate: data.sessionDate ? data.sessionDate.toDate() : null,
          });
        } else {
          console.log('No such document!');
        }
  
        const resultsRef = collection(db, 'results');
        const q = query(resultsRef, where('sessionKey', '==', sessionId));
        onSnapshot(q, (querySnapshot) => {
          const results: MBTIResult[] = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as MBTIResult;
            if (data.status !== 'deleted') { // Only add to results if status is not 'deleted'
              results.push({ id: doc.id, ...data });
            }
          });
  
          // Sort results by firstName in alphabetical order
          const sortedResults = results.sort((a, b) => a.firstName.localeCompare(b.firstName));  
          setMbtiResults(sortedResults);
        });
      }
    };
  
    fetchSessionDetails();
  }, [sessionId]);

  const handleDeleteResult = async (resultId: string) => {
    if (window.confirm('Are you sure you want to delete this student\'s result?')) {
      // Proceed with marking the document as deleted
      const resultRef = doc(db, 'results', resultId);
      try {
        await updateDoc(resultRef, {
          status: 'deleted'
        });
        console.log('Result successfully marked as deleted!');
      } catch (error) {
        console.error('Error updating document: ', error);
      }
    }
  };

  const restoreDeletedResults = async () => {
    if (window.confirm('Are you sure you want to restore all deleted results for this session?')) {
      const resultsRef = collection(db, 'results');
      const q = query(resultsRef, where('sessionKey', '==', sessionId), where('status', '==', 'deleted'));
  
      const querySnapshot = await getDocs(q);
      const batch = writeBatch(db);
  
      querySnapshot.forEach((documentSnapshot) => { // Renamed `doc` to `documentSnapshot` to avoid conflict
        const docRef = doc(db, 'results', documentSnapshot.id); // Now `doc` refers correctly to the Firestore `doc()` function
        batch.update(docRef, {
          status: deleteField()
        });
      });
  
      await batch.commit();
      console.log('All deleted results have been restored.');
    }
  };

  const exportToXLSX = (data: any[], fileName: string, headers: { key: string, header: string }[]) => {
    // Convert data to a format that includes readable headers
    const formattedData = [
      headers.map(h => h.header), // Headers as the first row
      ...data.map(item => headers.map(({ key }) => item[key])) // Data rows
    ];  
  
    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(formattedData, { skipHeader: true });
    
    // Set column widths
    const wscols = headers.map(() => ({ wch: 10 })); // Set each column width to 20
    worksheet['!cols'] = wscols;
  
    // Add headers manually to control their format
    XLSX.utils.sheet_add_aoa(worksheet, [headers.map(h => h.header)], { origin: 'A1' });
  
    // Create and export workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Results');
    XLSX.writeFile(workbook, sanitizeFilename(fileName) + '.xlsx');
  };
  
  const exportResults = () => {
    const headers = [
      { key: 'firstName', header: 'First Name' },
      { key: 'lastName', header: 'Last Name' },
      { key: 'mbtiType', header: 'MBTI Type' },
      { key: 'email', header: 'Email' },
      { key: 'mobilePhone', header: 'Mobile Phone' },
      // Explicitly add headers for MBTI scores in the desired order
      { key: 'E', header: 'E' },
      { key: 'I', header: 'I' },
      { key: 'S', header: 'S' },
      { key: 'N', header: 'N' },
      { key: 'T', header: 'T' },
      { key: 'F', header: 'F' },
      { key: 'J', header: 'J' },
      { key: 'P', header: 'P' },
    ];
  
    const filteredResults = mbtiResults.filter(result => result.status !== 'deleted');

    const data = filteredResults.map(result => ({
      firstName: result.firstName,
      lastName: result.lastName,
      mbtiType: result.mbtiType,
      email: result.email || "No email",
      mobilePhone: result.mobilePhone || "No phone",
      // Ensure scores are included in the correct order by explicitly referencing each score
      E: result.scores['E'] || '-',
      I: result.scores['I'] || '-',
      S: result.scores['S'] || '-',
      N: result.scores['N'] || '-',
      T: result.scores['T'] || '-',
      F: result.scores['F'] || '-',
      J: result.scores['J'] || '-',
      P: result.scores['P'] || '-',
    }));
  
    const fileName = `${sessionDetails.name} - ${filenameFormatDate(sessionDetails.sessionDate)} - MBTI`;
    exportToXLSX(data, fileName, headers);
  };
  
  const exportForClient = () => {
    const headers = [
      { key: 'firstName', header: 'First Name' },
      { key: 'lastName', header: 'Last Name' },
      { key: 'mbtiType', header: 'MBTI Type' }
    ];
    const filteredResults = mbtiResults.filter(result => result.status !== 'deleted');

    const data = filteredResults.map(result => ({
      firstName: result.firstName,
      lastName: result.lastName,
      mbtiType: result.mbtiType
    }));
    const fileName = `${sessionDetails.name} - ${filenameFormatDate(sessionDetails.sessionDate)} - MBTI For Client`;
    exportToXLSX(data, fileName, headers);
  };

  const formatDate = (date: Date | null | undefined): string => {
    return date ? moment(date).format('D MMM YYYY') : 'Unknown date';
  };

  const filenameFormatDate = (date: Date | null | undefined) => {
    return date ? moment(date).format('YYYYMMDD') : '';
  };

  const sanitizeFilename = (name: string) => {
    return name.replace(/[/\\?%*:|"<>]/g, '_'); // Replace disallowed characters with an underscore
  };

  const closeCertificatePreview = () => {
    setShowCertificate(false);
  };

  const displayCert = (result: MBTIResult, index: number) => {
    setSelectedResult(result);
    setCurrentIndex(index);
    setShowCertificate(true);
  };

  const Certificate = ({ firstName, lastName, mbtiType, scores, sessionDate }: CertificateProps) => {
    Font.register({
      family: 'Open Sans',
      format: 'truetype',
      src: OpenSansRegular,
      fontWeight: 'normal',
    });

    Font.register({
      family: 'Open Sans',
      format: 'truetype',
      src: OpenSansBold,
      fontWeight: 'bold',
    });

    Font.register({
      family: 'Bebas Neue',
      format: 'truetype',
      src: BebasNeueRegular,
    });

    const styles = StyleSheet.create({
      certificateContainer: {
        flexDirection: 'row',
        alignItems: 'stretch',
        height: '100vh',
        fontFamily: 'Open Sans',
      },
      leftContainer: {
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'center',
        backgroundColor: '#004AAD',
        color: 'white',
        padding: '32pt',
        gap: '32pt',
      },
      leftHeader: {
        fontWeight: 'bold',
        letterSpacing: 2, // Note: letter-spacing may not be supported
        fontSize: 12,
      },
      companyLogo: {
        position: 'absolute',
        top: '20pt',
        left: '20pt',
        width: '64pt',
        height: 'auto',
      },
      mbtiCertifiedLogo: {
        position: 'absolute',
        bottom: '20pt',
        right: '20pt',
        width: '48pt',
        height: 'auto',
      },
      assessmentDate: {
        position: 'absolute',
        bottom: '20pt',
        left: '20pt',
        fontSize: 12,
      },
      studentName: {
        fontFamily: 'Bebas Neue',
        fontSize: 64,
        letterSpacing: 3,
        lineHeight: 1,
        margin: 0,
      },
      rightContainer: {
        padding: '20pt',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
      scoresContainer: {
        flexDirection: 'row',
        width: '80%',
        marginBottom: '40pt',
        gap: '64pt',
      },
      scoreItemChar: {
        fontFamily: 'Bebas Neue',
        fontSize: 40,
        textAlign: 'center',
        position: 'relative',
        borderBottom: '12pt',
        borderBottomColor: '#FFE23E',
        width: '72pt',
      },
      scoreItemScores: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '12pt',
      },
      boldText: {
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
      },
    });

    const mbtiChars = mbtiType.split('');

    return (
      <Document>
        <Page size='A4' orientation='landscape' style={styles.certificateContainer}>
          <View style={styles.leftContainer}>
            <Image src={CompanyLogo} style={styles.companyLogo} />
            <Text style={styles.leftHeader}>MBTI PERSONALITY TYPE</Text>
            <Text style={styles.studentName}>{firstName} {lastName}</Text>
            <Text style={styles.assessmentDate}>Assessed on {moment(sessionDate).format('D MMM YYYY')}</Text>
          </View>
          <View style={styles.rightContainer}>
            <View style={styles.scoresContainer}>
              <Text style={styles.scoreItemChar}>{mbtiChars[0]}</Text>
              <View style={styles.scoreItemScores}>
                <Text style={styles.boldText}>E</Text>
                <Text>&nbsp;:&nbsp;{scores.E}&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                <Text style={styles.boldText}>I</Text>
                <Text>&nbsp;:&nbsp;{scores.I}</Text>
              </View>
            </View>
            <View style={styles.scoresContainer}>
              <Text style={styles.scoreItemChar}>{mbtiChars[1]}</Text>
              <View style={styles.scoreItemScores}>
                <Text style={styles.boldText}>S</Text>
                <Text>&nbsp;:&nbsp;{scores.S}&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                <Text style={styles.boldText}>N</Text>
                <Text>&nbsp;:&nbsp;{scores.N}</Text>
              </View>
            </View>
            <View style={styles.scoresContainer}>
              <Text style={styles.scoreItemChar}>{mbtiChars[2]}</Text>
              <View style={styles.scoreItemScores}>
                <Text style={styles.boldText}>T</Text>
                <Text>&nbsp;:&nbsp;{scores.T}&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                <Text style={styles.boldText}>F</Text>
                <Text>&nbsp;:&nbsp;{scores.F}</Text>
              </View>
            </View>
            <View style={styles.scoresContainer}>
              <Text style={styles.scoreItemChar}>{mbtiChars[3]}</Text>
              <View style={styles.scoreItemScores}>
                <Text style={styles.boldText}>J</Text>
                <Text>&nbsp;:&nbsp;{scores.J}&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                <Text style={styles.boldText}>P</Text>
                <Text>&nbsp;:&nbsp;{scores.P}</Text>
              </View>
            </View>
            <Image src={mbtiCertifiedLogo} style={styles.mbtiCertifiedLogo} />
          </View>
        </Page>
      </Document>
    );
  }

  const CertificatePreview = ({ firstName, lastName, mbtiType, scores, sessionDate }: CertificateProps) => {
    const mbtiChars = mbtiType.split('');
    return (
      <div className='certificate-container'>
        <div className='left-container'>
          <img src={CompanyLogo} className='company-logo' />
          <div className='left-header'>MBTI PERSONALITY TYPE</div>
          <div className='student-name'>{firstName} {lastName}</div>
          <div className='assessment-date'>Assessed on {moment(sessionDate).format('D MMM YYYY')}</div>
        </div>
        <div className='right-container'>
          <div className='scores-container'>
            <div className='score-item-char'>{mbtiChars[0]}</div>
            <div className='score-item-scores'><b>E</b><span>:</span>{scores.E}<span>//</span><b>I</b><span>:</span>{scores.I}</div>
          </div>
          <div className='scores-container'>
            <div className='score-item-char'>{mbtiChars[1]}</div>
            <div className='score-item-scores'><b>S</b><span>:</span>{scores.S}<span>//</span><b>N</b><span>:</span>{scores.N}</div>
          </div>
          <div className='scores-container'>
            <div className='score-item-char'>{mbtiChars[2]}</div>
            <div className='score-item-scores'><b>T</b><span>:</span>{scores.T}<span>//</span><b>F</b><span>:</span>{scores.F}</div>
          </div>
          <div className='scores-container'>
            <div className='score-item-char'>{mbtiChars[3]}</div>
            <div className='score-item-scores'><b>J</b><span>:</span>{scores.J}<span>//</span><b>P</b><span>:</span>{scores.P}</div>
          </div>
          <img src={mbtiCertifiedLogo} className='mbti-certified-logo' />
        </div>
      </div>
    );
  };

  const goToNextResult = () => {
    if (currentIndex !== null && currentIndex < mbtiResults.length - 1) {
      const newIndex = currentIndex + 1;
      setSelectedResult(mbtiResults[newIndex]);
      setCurrentIndex(newIndex);
    }
  };
  
  const goToPreviousResult = () => {
    if (currentIndex !== null && currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setSelectedResult(mbtiResults[newIndex]);
      setCurrentIndex(newIndex);
    }
  };

  return (
    <div className='mainContainer flex-gap-column'>
      <div>
        {sessionDetails && (
          <div>
            <h1>Results for<br/>{sessionDetails.name}</h1>
            <p>Conducted on {formatDate(sessionDetails.sessionDate)}</p>
            <p>Total Results: {mbtiResults.length}</p>
          </div>
        )}
      </div>
      <div className='flex-gap'>
        <button onClick={ exportResults }>Export Results</button>
        <button onClick={ exportForClient }>Export for Client</button>
      </div>
      <div style={{ textAlign: 'center' }}>
        <a href='#' onClick={restoreDeletedResults}>Deleted by mistake? Restore all deletions.</a>
      </div>
      <div className='student-results-container'>
        {mbtiResults.map((result, index) => (
          <div key={index} className='student-result-item'>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
              <div style={{ flex: 1 }}>{result.firstName} {result.lastName}</div>
              <div>{result.mbtiType}</div>
              <div><a href='#' onClick={() => result.id && handleDeleteResult(result.id)}><FontAwesomeIcon icon={faTrash} /></a></div>
            </div>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <span><FontAwesomeIcon icon={faEnvelope} /> {result.email || "No email"}</span>
              <span><FontAwesomeIcon icon={faMobile} /> {result.mobilePhone || "No phone"}</span>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gap: '10px' }}>
              {/* Headers */}
              <div style={{ textAlign: 'center' }}><strong>E</strong></div>
              <div style={{ textAlign: 'center' }}><strong>I</strong></div>
              <div style={{ textAlign: 'center' }}><strong>S</strong></div>
              <div style={{ textAlign: 'center' }}><strong>N</strong></div>
              <div style={{ textAlign: 'center' }}><strong>T</strong></div>
              <div style={{ textAlign: 'center' }}><strong>F</strong></div>
              <div style={{ textAlign: 'center' }}><strong>J</strong></div>
              <div style={{ textAlign: 'center' }}><strong>P</strong></div>
              {/* Scores */}
              <div style={{ textAlign: 'center' }}>{result.scores['E'] || '-'}</div>
              <div style={{ textAlign: 'center' }}>{result.scores['I'] || '-'}</div>
              <div style={{ textAlign: 'center' }}>{result.scores['S'] || '-'}</div>
              <div style={{ textAlign: 'center' }}>{result.scores['N'] || '-'}</div>
              <div style={{ textAlign: 'center' }}>{result.scores['T'] || '-'}</div>
              <div style={{ textAlign: 'center' }}>{result.scores['F'] || '-'}</div>
              <div style={{ textAlign: 'center' }}>{result.scores['J'] || '-'}</div>
              <div style={{ textAlign: 'center' }}>{result.scores['P'] || '-'}</div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <a href='#' onClick={() => displayCert(result, index)}>View Certificate <FontAwesomeIcon icon={faCertificate} /></a>
            </div>
          </div>
        ))}
      </div>
      <ListEnd />
      {showCertificate && selectedResult && sessionDetails.sessionDate && (
        <div className='certificate-preview-overlay'>
          <div className='certificate-preview-container'>
            <div className='certificate-preview-overlay-menu'>
              <div className='overlay-icon-button' onClick={closeCertificatePreview}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
            <div hidden>
              <Certificate
                firstName={selectedResult.firstName}
                lastName={selectedResult.lastName}
                mbtiType={selectedResult.mbtiType}
                scores={selectedResult.scores}
                sessionDate={sessionDetails.sessionDate}
              />
            </div>
            <CertificatePreview
              firstName={selectedResult.firstName}
              lastName={selectedResult.lastName}
              mbtiType={selectedResult.mbtiType}
              scores={selectedResult.scores}
              sessionDate={sessionDetails.sessionDate}
            />
            <div className='certificate-preview-overlay-footer'>
              <div className={`overlay-icon-button ${currentIndex === 0 ? 'disabled' : ''}`} onClick={goToPreviousResult}><FontAwesomeIcon icon={faChevronLeft} /></div>
              <div style={{ flex: 1, padding: '0 2rem' }}>
                <PDFDownloadLink
                  document={<Certificate
                    firstName={selectedResult.firstName}
                    lastName={selectedResult.lastName}
                    mbtiType={selectedResult.mbtiType}
                    scores={selectedResult.scores}
                    sessionDate={sessionDetails.sessionDate}
                  />}
                  fileName={`${selectedResult.firstName} ${selectedResult.lastName} ${selectedResult.mbtiType} ${moment(sessionDetails.sessionDate).format('YYYYMMDD')}.pdf`}
                >
                  {({ blob, url, loading, error }) => {
                    // Handle error state
                    if (error) {
                      setPdfError("An error occurred while generating the certificate. Please try again.");
                      return null;
                    }

                    // Clear error state when loading is complete or the blob is available
                    if (loading || blob) {
                      setPdfError(null);
                    }

                    // Return a button-like element for the link
                    return (
                      <button className='overlay-button'>
                        {loading ? 'Loading...' : (
                          <>
                            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                            Download Certificate
                          </>
                        )}
                      </button>
                    );
                  }}
                </PDFDownloadLink>
              </div>
              <div className={`overlay-icon-button ${currentIndex === mbtiResults.length - 1 ? 'disabled' : ''}`} onClick={goToNextResult}><FontAwesomeIcon icon={faChevronRight} /></div>

              {pdfError && (
                <div style={{ color: 'red' }}>
                  {pdfError}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentResults;
