// src/components/Header.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="adminHeader">
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <div className="adminTitle">Administrator</div>
          <div>
            <button className="hamburgerButton" onClick={() => setMenuOpen(!menuOpen)}>
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
        </div>
        <div>
          <nav className={`adminNav ${menuOpen ? 'adminNavOpen' : ''}`}>
            <ul>
              <li><Link to="/admin/training-session" onClick={closeMenu}>Training Sessions</Link></li>
              {/* <li><Link to="/admin/results" onClick={closeMenu}>View Results</Link></li>
              <li><Link to="/admin/settings" onClick={closeMenu}>Settings</Link></li> */}
              <li><Link to="/logout" onClick={closeMenu}>Logout</Link></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;