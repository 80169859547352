import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config';

type ProtectedRouteProps = {
  children: ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, loading, logout } = useAuth();
  const [authChecked, setAuthChecked] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        // Ideally, this should not be needed if your context's onAuthStateChanged is set up correctly
        // However, if you're looking to ensure 'logout' or similar action, you can call it here
        await logout(); // Ensure this function is async
      }
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, [logout]);

  if (loading || !authChecked) {
    return <div>Loading...</div>; // Consider a more robust loading indicator
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
