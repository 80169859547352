// src/utils/generateHashKey.ts
import CryptoJS from 'crypto-js';

const SECRET_STRING = 'yourSecretString'; // Replace this with your actual secret string

export const generateHashKey = (): string => {
  // Get the current date in yyyy-mm-dd format
  const dateStamp = new Date().toISOString().split('T')[0];

  // Concatenate the date stamp with the secret string
  const rawKey = `${dateStamp}-${SECRET_STRING}`;

  // Generate a SHA256 hash of the raw key
  const hash = CryptoJS.SHA256(rawKey).toString(CryptoJS.enc.Hex);

  // Return the first 32 characters of the hash
  return hash.substr(0, 32);
};