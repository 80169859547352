import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';

const DashboardLayout = () => {
  return (
    <div>
      <Header />
      <main>
        <Outlet /> {/* This will render the nested routes */}
      </main>
    </div>
  );
};

export default DashboardLayout;
