// src/utils/validateHashKey.ts
import React, { doc, getDoc } from 'firebase/firestore';
import { generateHashKey } from './generateHashKey';
import { db } from '../firebase-config'; // Adjust the import path if necessary
import { collection, query, where, getDocs } from 'firebase/firestore';

export const validateHashKey = async (incomingHash: string): Promise<boolean> => {
  const expectedHash = generateHashKey();
  
  if (incomingHash === expectedHash) {
    return true;
  }

  // Get the document with the incomingHash as its ID
  const docRef = doc(db, 'trainingSessions', incomingHash);
  const docSnapshot = await getDoc(docRef);

  // Check if the document exists and has a status of 'created' or 'completed'
  return docSnapshot.exists() && ['created', 'completed'].includes(docSnapshot.data()?.status);
};