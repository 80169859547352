// src/components/AdminPage.tsx
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../styles/global.css';
import '../styles/AdminPage.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const AdminPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const errorCodes: { [key: string]: string } = {
    'auth/invalid-credential': 'The email and/or password is incorrect. Please try again.',
    'auth/user-not-found': 'No user found with this email. Please try again.',
    'auth/wrong-password': 'The email and/or password is incorrect. Please try again.',
    // ...other error codes
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await login(username, password);
      navigate('/admin'); // Redirect on successful login
    } catch (error: any) {
      const errorMessage = errorCodes[error.code] || 'An unexpected error occurred. Please try again.';
      setError(errorMessage); // Set the error message to display to the user
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={'loginContainer'}>
      <form onSubmit={handleLogin} className={'loginForm'}>
        {error && <div className="errorMessage" style={{ marginBottom: 20 }}>{error}</div>} {/* Display login error if any */}
        <div className="formGroup">
          <label htmlFor="username" className="label">Username:</label>
          <input
            type="email"
            id="username"
            className="input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="formGroup">
          <label htmlFor="password" className="label">Password:</label>
          <div className="passwordContainer">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              className="input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{ paddingRight: '30px' }} // Add right padding to prevent text from overlapping the icon
            />
            <div
              onClick={togglePasswordVisibility}
              className="togglePasswordButton"
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </div>
          </div>
        </div>
        <button type="submit" className="button">Log In</button>
      </form>
    </div>
  );
};

export default AdminPage;
