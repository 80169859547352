// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './context/ProtectedRoute';
import DefaultLandingPage from './components/DefaultLandingPage';
import AdminPage from './components/AdminPage';
import DashboardLayout from './components/DashboardLayout';
import Settings from './components/Settings';
import ViewResults from './components/ViewResults';
import StudentEntry from './components/StudentEntry';
import ResultsPage from './components/ResultsPage';
import StudentDataForm from './components/StudentDataForm';
import TrainingSession from './components/TrainingSession';
import StudentResults from './components/StudentResults';

const App = () => (
  <Router>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<AdminPage />} />
        <Route path="/admin" element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }>
          <Route index element={<TrainingSession />} />
          <Route path="training-session" element={<TrainingSession />} />
          <Route path="results/:sessionId" element={<StudentResults />} />
          {/* <Route path="results" element={<ViewResults />} />
          <Route path="settings" element={<Settings />} /> */}
        </Route>
        <Route path="/student/:hashKey" element={<StudentEntry />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/student-data-form" element={<StudentDataForm />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </AuthProvider>
  </Router>
);

export default App;
