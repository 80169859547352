// src/components/StudentLandingPage.tsx
import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { db } from '../firebase-config';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import '../styles/questions.css';
import '../types/types';
import { EnglishQuestionText, Question, Scores, TranslatedQuestion } from '../types/types';
import { useNavigate } from 'react-router-dom';

const initialScores: Scores = {
  E: 0,
  I: 0,
  S: 0,
  N: 0,
  T: 0,
  F: 0,
  J: 0,
  P: 0,
};

const devMode = process.env.REACT_APP_DEVMODE === 'true';

const StudentLandingPage = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [current, setCurrent] = useState(0);
  const [answers, setAnswers] = useState<(0 | 1 | 2 | null)[]>([]);
  const sliderRef = useRef<Slider>(null);
  const navigate = useNavigate();
  const hashKeyForToday = localStorage.getItem('sessionHashKey');
  const [englishQuestionsText, setEnglishQuestionsText] = useState<EnglishQuestionText[]>([]);
  const [translatedQuestions, setTranslatedQuestions] = useState<TranslatedQuestion[]>([]);
  const [translatedLanguage, setTranslatedLanguage] = useState<string>('');
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [buttonTranslations, setButtonTranslations] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (currentSlide: number, nextSlide: number) => {
      const lastAnsweredIdx = getLastAnsweredIndex(answers);
      
      // Allow swiping left always
      if (nextSlide < currentSlide) {
        setCurrent(nextSlide);
        return;
      }
  
      // Allow swiping right only if it's to the next question after the last answered question
      if (nextSlide <= lastAnsweredIdx + 1) {
        setCurrent(nextSlide);
      } else {
        // If the next slide is beyond the last answered question + 1,
        // force the slider to go to the last allowed slide (lastAnsweredIdx + 1)
        // Use setTimeout to ensure this runs after the internal handling of the event by react-slick
        setTimeout(() => {
          sliderRef.current?.slickGoTo(lastAnsweredIdx + 1);
          setCurrent(lastAnsweredIdx + 1);
        }, 550);
      }
    },
    afterChange: (currentSlide: number) => {
      setCurrent(currentSlide);
      // Automatically navigate to the next question after answering
      if (answers[currentSlide] !== null) {
        sliderRef.current?.slickNext();
      }
    },
    swipeToSlide: true
  };

  // UI translations
  useEffect(() => {
    const loadTranslations = async () => {
      // Fetching the current session's language setting
      const sessionHashKey = localStorage.getItem('sessionHashKey');
      if (sessionHashKey) {
        const sessionDocRef = doc(db, 'trainingSessions', sessionHashKey);
        const sessionDocSnap = await getDoc(sessionDocRef);
        if (sessionDocSnap.exists()) {
          const sessionData = sessionDocSnap.data();
          const languageCode = sessionData.language;
          if (languageCode && languageCode !== 'en') {
            // Fetch translations if the language is not English
            const translationsRef = doc(db, 'translatedUI', languageCode);
            const translationsSnap = await getDoc(translationsRef);
            if (translationsSnap.exists()) {
              // Assuming structure is like { answerButtons: ['Not like me at all', 'Somewhat like me', 'Exactly like me'] }
              setButtonTranslations(translationsSnap.data().answerButtons);
            } else {
              console.error(`No translations found for language: ${languageCode}`);
            }
          }
        } else {
          console.error('No session document found');
        }
      }
    };
    loadTranslations();
  }, []);

  useEffect(() => {
    const sessionHashKey = localStorage.getItem('sessionHashKey');
    if (sessionHashKey) {
      const sessionDataString = localStorage.getItem('sessionData');
      if (sessionDataString) {
        const sessionData = JSON.parse(sessionDataString);
        if (sessionData[sessionHashKey] && sessionData[sessionHashKey].currentQuestionIndex !== undefined) {
          // Use a setTimeout to ensure that the slider has mounted and rendered by the time we try to navigate to a slide
          setTimeout(() => {
            // Go to the next question after the saved one (savedIndex + 1)
            sliderRef.current?.slickGoTo(sessionData[sessionHashKey].currentQuestionIndex);
          }, 500);
        }
      }
    }
  }, []);

  useEffect(() => {
    // Retrieve the session hash key from local storage
    const sessionHashKey = localStorage.getItem('sessionHashKey');
    if (sessionHashKey) {
      // If we have a session hash key, fetch questions and translations for it
      fetchQuestionsAndTranslations(sessionHashKey).catch(console.error);
    } else {
      // If we don't have a session hash key, log an error
      console.error('Session hash key is not available.');
    }
    // The empty dependency array ensures this effect runs once on mount
  }, []);

  // Save state to localStorage whenever answers or current question changes
  useEffect(() => {
    if (answers.length > 0 && current !== null) {
      // New logic: updating the session data
      const currentSessionHashKey = localStorage.getItem('sessionHashKey');
      if (currentSessionHashKey) {
        const sessionData = JSON.parse(localStorage.getItem('sessionData') || '{}');
        
        // Update only the current session's data
        if (sessionData[currentSessionHashKey]) {
          sessionData[currentSessionHashKey].studentAnswers = answers;
          sessionData[currentSessionHashKey].currentQuestionIndex = current;

          // Save the updated session data back to localStorage
          localStorage.setItem('sessionData', JSON.stringify(sessionData));
        }
      }
    }
  }, [answers, current]);

  useEffect(() => {
    const sessionHashKey = localStorage.getItem('sessionHashKey');
    if (!sessionHashKey) {
      console.error('Session hash key is not available.');
      return; // Exit if no session key
    }
  
    const sessionData = JSON.parse(localStorage.getItem('sessionData') || '{}');
    if (sessionData[sessionHashKey] && sessionData[sessionHashKey].studentQuestions.length > 0) {
      // Use the questions from sessionData
      setQuestions(sessionData[sessionHashKey].studentQuestions);
      setAnswers(sessionData[sessionHashKey].studentAnswers || Array(80).fill(null));
      setCurrent(sessionData[sessionHashKey].currentQuestionIndex || 0);
    } else {
      // Only fetch from Firestore if there's no session data
      fetchQuestionsAndTranslations(sessionHashKey).catch(console.error);
    }
  }, []);

  useEffect(() => {
    // Only perform this useEffect if devMode is false
    if (!devMode && isComponentMounted) {
      const sessionHashKey = localStorage.getItem('sessionHashKey');
      if (sessionHashKey) {
        const sessionDataString = localStorage.getItem('sessionData');
        if (sessionDataString) {
          const sessionData = JSON.parse(sessionDataString);
          if (sessionData[sessionHashKey] && sessionData[sessionHashKey].currentQuestionIndex !== undefined) {
            // Use a setTimeout to ensure that the slider has mounted and rendered by the time we try to navigate to a slide
            setTimeout(() => {
              // Go to the next question after the saved one (savedIndex + 1)
              sliderRef.current?.slickGoTo(sessionData[sessionHashKey].currentQuestionIndex + 1);
            }, 500);
          }
        }
      }
    }
  // Include devMode in the dependency array
  }, [isComponentMounted]);

  useEffect(() => {
    // For development: Automatically fill answers randomly
    if (devMode && questions.length > 0) {
      // Generate random answers
      const randomAnswers = generateRandomAnswers();
      // Log the randomAnswers for debugging purposes
      console.log('Random answers for devMode:', randomAnswers);
  
      // Set the random answers
      setAnswers(randomAnswers);
      
      // Set the current index to the last question index
      setCurrent(randomAnswers.length - 1); // This is not updating and reverts back to 0, likely due to asynchronous. Leave it alone for now.
      
      // Optionally navigate to the results or data form directly
      // navigate('/student-data-form', { state: { sessionId: hashKeyForToday } });
    }
  // Include devMode and questions in the dependency array
  }, [devMode, questions]);

  const generateRandomAnswers = (): (0 | 1 | 2)[] => {
    // Simply generate an array of 80 items with each item being 1, 2, or 3
    return Array.from({ length: 80 }, () => Math.floor(Math.random() * 3) + 1 as 0 | 1 | 2);
  };

  // Helper function to fetch English questions
  const fetchEnglishQuestions = async () => {
    const q = query(collection(db, 'questionsEng'), where('status', '==', 'available'));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    } as Question)).sort(() => 0.5 - Math.random()); // Randomize the questions
  };

  // Helper function to fetch only the English question texts
  const fetchEnglishQuestionText = async (): Promise<EnglishQuestionText[]> => {
    const q = query(collection(db, 'questionsEng'), where('status', '==', 'available'));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      question: doc.data().question, // Assuming 'question' is the field name for the question text
    }));
  };

  // Call fetchEnglishQuestionText inside useEffect on component mount
  useEffect(() => {
    const setEnglishQuestionsTexts = async () => {
      const questionsTexts = await fetchEnglishQuestionText();
      setEnglishQuestionsText(questionsTexts); // Store the entire objects
    };
  
    setEnglishQuestionsTexts().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Helper function to fetch Translated questions
  const fetchTranslatedQuestions = async (language: string) => {
    const translations: { id: string, translatedQuestion: string }[] = [];
    try {
      const tq = query(collection(db, 'translatedQuestions'));
      const translatedQuerySnapshot = await getDocs(tq);
      if (translatedQuerySnapshot.empty) {
        console.log('No translated questions found.');
        return translations;
      }
      translatedQuerySnapshot.forEach((doc) => {
        const questionData = doc.data();
        const translation = questionData.questions ? questionData.questions[language] : null;
        if (translation) {
          translations.push({ id: doc.id, translatedQuestion: translation });
        }
      });
      return translations;
    } catch (error) {
      console.error("Error fetching translated questions:", error);
      return translations;
    }
  };

  // Combine fetching logic for English and Translated questions
  const fetchQuestionsAndTranslations = async (sessionHashKey: string) => {
    setIsLoading(true); // Start loading

    if (!sessionHashKey) {
      console.error('Session hash key is not available.');
      return;
    }
  
    let fetchedQuestions: Question[] = [];
    const sessionData = JSON.parse(localStorage.getItem('sessionData') || '{}');
  
    if (!sessionData[sessionHashKey] || sessionData[sessionHashKey].studentQuestions.length === 0) {
      // Fetch new English questions
      fetchedQuestions = await fetchEnglishQuestions();
      // Store the necessary question details in sessionData
      sessionData[sessionHashKey] = {
        ...sessionData[sessionHashKey],
        studentQuestions: fetchedQuestions.map(q => ({ id: q.id, type_id: q.type_id })),
        studentAnswers: new Array(fetchedQuestions.length).fill(null),
        currentQuestionIndex: 0,
      };

      // Save the updated session data to localStorage
      localStorage.setItem('sessionData', JSON.stringify(sessionData));
    } else {
      // Use existing session data which already has randomized questions
      fetchedQuestions = sessionData[sessionHashKey].studentQuestions;
    }
  
    // Fetch the language and translated questions if language is not English
    const sessionDocRef = doc(db, 'trainingSessions', sessionHashKey);
    const docSnapshot = await getDoc(sessionDocRef);
    if (docSnapshot.exists()) {
      const language = docSnapshot.data().language;
      setTranslatedLanguage(language);
      if (language && language !== 'en') {
        const translations = await fetchTranslatedQuestions(language);
        // Update state with translations
        setTranslatedQuestions(translations);
      }
    } else {
      console.error('No such session document!');
      return;
    }
  
    // Save the session data to localStorage
    localStorage.setItem('sessionData', JSON.stringify(sessionData));
  
    // Set the state with the new session data
    setQuestions(fetchedQuestions);
    setAnswers(sessionData[sessionHashKey].studentAnswers);
    setCurrent(sessionData[sessionHashKey].currentQuestionIndex);

    setIsLoading(false); // Finish loading
  };

  useEffect(() => {
    // Fetch the session language from Firestore and load translated questions if available
    const loadSessionLanguage = async () => {
      const sessionHashKey = localStorage.getItem('sessionHashKey');
      if (sessionHashKey) {
        const sessionDocRef = doc(db, 'trainingSessions', sessionHashKey);
        const docSnapshot = await getDoc(sessionDocRef);
        if (docSnapshot.exists()) {
          const language = docSnapshot.data().language;
          setTranslatedLanguage(language);
        } else {
          console.error('No such session document!');
          // Handle the case where the document does not exist
        }
      }
    };
  
    loadSessionLanguage();
  }, []);

  // To handle the resize event
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Listen for resize events
    window.addEventListener('resize', setVh);
    // Set the variable initially
    setVh();

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  const getLastAnsweredIndex = (answers: (0 | 1 | 2 | null)[]): number => {
    // Find the last index where the answer is not null
    for (let i = answers.length - 1; i >= 0; i--) {
      if (answers[i] !== null) {
        return i;
      }
    }
    return -1;  // Return -1 if no answers are found
  };

  // When the student completes all questions
  const handleComplete = () => {
    navigate('/student-data-form', { state: { sessionId: hashKeyForToday } });
  };

  // When the student answers a question
  const handleAnswer = (index: number, answer: 0 | 1 | 2) => {
    const newAnswers = [...answers];
    newAnswers[index] = answer;
    setAnswers(newAnswers);
    setCurrent(index); // Ensure the current index is updated
  
    const currentSessionHashKey = localStorage.getItem('sessionHashKey');
    if (currentSessionHashKey) {
      const sessionData = JSON.parse(localStorage.getItem('sessionData') || '{}');
  
      // Update only the current session's data
      if (sessionData[currentSessionHashKey]) {
        sessionData[currentSessionHashKey].studentAnswers = newAnswers;
        sessionData[currentSessionHashKey].currentQuestionIndex = index;
  
        // Save the updated session data back to localStorage
        localStorage.setItem('sessionData', JSON.stringify(sessionData));
      }
    }
  
    // Proceed to next question or handle completion
    if (sliderRef.current) {
      if (index < questions.length) {
        sliderRef.current.slickNext();
      }
    }
  };

  return (
    <div className="mainContainer">
      {isLoading ? (
        <p>Loading questions...</p> // Show loading indicator while data is being fetched
      ) : (
        <Slider ref={sliderRef} {...settings}>
          {englishQuestionsText.length > 0 && questions.map((question, index) => {
            const lastAnsweredIdx = getLastAnsweredIndex(answers);
            const isCurrentQuestionAnswerable = index <= lastAnsweredIdx + 1;
            const translationObj = translatedQuestions.find(t => t.id === question.id);
            const hasTranslation = translationObj && translationObj.translatedQuestion;
            const englishText = englishQuestionsText.find(q => q.id === question.id)?.question;

            return (
              <div key={question.id} className="slideContainer">
                <div style={{ padding: "0 20px" }}>
                  <div className="questionCounter">
                    {index + 1}/{questions.length}
                  </div>
                  <div className="questionText" style={{ fontSize: hasTranslation ? '1.25em' : '1.5em' }}>
                  <p>{englishText}</p>
                    {translationObj && (
                      <div className='translatedQuestionText'>{translationObj.translatedQuestion}</div>
                    )}
                  </div>
                  <div className="buttonContainer flex-gap-column" style={{ marginTop: '1rem' }}>
                    <button
                      className={`answerButton notlikeme ${answers[index] === 0 ? 'selected' : ''}`}
                      onClick={() => handleAnswer(index, 0)}
                      disabled={!isCurrentQuestionAnswerable}
                    >
                      Not like me at all<br/>{buttonTranslations[0]}
                    </button>
                    <button
                      className={`answerButton somewhatlikeme ${answers[index] === 1 ? 'selected' : ''}`}
                      onClick={() => handleAnswer(index, 1)}
                      disabled={!isCurrentQuestionAnswerable}
                    >
                      Somewhat like me<br/>{buttonTranslations[1]}
                    </button>
                    <button
                      className={`answerButton exactlylikeme ${answers[index] === 2 ? 'selected' : ''}`}
                      onClick={() => handleAnswer(index, 2)}
                      disabled={!isCurrentQuestionAnswerable}
                    >
                      Exactly like me<br/>{buttonTranslations[2]}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
          {/* Additional slide for completion instructions */}
          {englishQuestionsText.length > 0 && (
            <div className="slideContainer">
              <div style={{ padding: "0 20px", fontSize: '1.2rem' }}>
                <div className="completionInstructions">
                  <p>You have completed all the questions. You can review your answers again, or proceed to submit your answers as final.</p>
                  {
                    buttonTranslations[3] ? (
                      <p style={{ fontSize: 'smaller' }}>{buttonTranslations[3]}</p>
                    ): null
                  }
                  <button className="submitAnswersButton" onClick={handleComplete} style={{ margin: 'auto', marginTop: '1rem' }}>
                    Submit Answers
                    {
                    buttonTranslations[4] ? (
                      <><br/>{buttonTranslations[4]}</>
                    ): null
                  }
                  </button>
                </div>
              </div>
            </div>
          )}
        </Slider>      
      )}
    </div>
  );
};

export default StudentLandingPage;
