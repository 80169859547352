// src/components/TrainingSession.tsx
import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import {
  collection,
  addDoc,
  Timestamp,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import QRCode from "qrcode.react";
import "../styles/global.css";
import { Session, Language } from "../types/types";
import { AppConfig } from "../config/AppConfig";
import { formatDate, timestampToDateInputValue } from "../utils/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ListEnd from "./ListEnd";
import { Link } from "react-router-dom";

const TrainingSession = () => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    sessionDate: "",
    showResult: false,
  });
  const [editingSessionId, setEditingSessionId] = useState<string | null>(null);
  const [documentId, setDocumentId] = useState<string | null>(null); // State to store document ID
  const [originalData, setOriginalData] = useState<Session | null>(null); // State to store original data in Edit mode
  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [sessionResults, setSessionResults] = useState<any[]>([]);

  useEffect(() => {
    if (editingSessionId) {
      const q = query(collection(db, "results"), where("sessionKey", "==", editingSessionId));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const results = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSessionResults(results);
      });
  
      return () => unsubscribe(); // Clean up the listener when the component unmounts or the editingSessionId changes
    }
  }, [editingSessionId]);

  useEffect(() => {
    // Fetch available languages from Firestore and update the languages state
    const fetchLanguages = async () => {
      const q = query(
        collection(db, "languages"),
        where("status", "==", "available")
      );
      const querySnapshot = await getDocs(q);
      const fetchedLanguages: Language[] = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() } as Language))
        .filter((language) => language.name !== "English"); // Omit English from the list
      setLanguages(fetchedLanguages);
    };

    fetchLanguages();
  }, []);

  useEffect(() => {
    // Adjust the query to fetch sessions with status 'created' or 'completed'
    const q = query(
      collection(db, "trainingSessions"),
      where("status", "in", ["created", "completed"])
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const sessionList: Session[] = [];
      querySnapshot.forEach((doc) => {
        sessionList.push({ ...doc.data(), id: doc.id } as Session);
      });
      setSessions(sessionList);
      setLoading(false);
    });

    return () => unsubscribe(); // Detach listener on unmount
  }, []);

  useEffect(() => {
    const fetchSessions = async () => {
      const q = query(
        collection(db, "trainingSessions"),
        where("status", "==", "created")
      );
      const querySnapshot = await getDocs(q);
      setSessions(
        querySnapshot.docs.map(
          (doc) => ({ ...doc.data(), id: doc.id } as Session)
        )
      );
      setLoading(false);
    };

    fetchSessions();
  }, []);

  // Effect to set the selected language when editing
  useEffect(() => {
    if (editingSessionId) {
      // Find the session being edited
      const sessionToEdit = sessions.find(
        (session) => session.id === editingSessionId
      );
      if (sessionToEdit) {
        const sessionDateValue = timestampToDateInputValue(
          sessionToEdit.sessionDate
        );
        setSelectedLanguage(sessionToEdit.language);
        // Populate the rest of the form with the session data
        setFormData({
          name: sessionToEdit.name,
          sessionDate: sessionDateValue,
          showResult: sessionToEdit.showResult || false,
        });
      }
    }
  }, [editingSessionId, sessions]);

  const hasFormChanged = () => {
    const originalSessionDate = originalData?.sessionDate
      ? new Date(originalData.sessionDate.seconds * 1000)
          .toISOString()
          .substring(0, 10)
      : null;
    return (
      formData.name !== originalData?.name ||
      formData.sessionDate !== originalSessionDate
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value === "" ? null : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Ensure sessionDate is converted to a Timestamp before updating Firestore
    const sessionDate = formData.sessionDate
      ? Timestamp.fromDate(new Date(formData.sessionDate))
      : null;

    const sessionData = {
      ...formData,
      language: selectedLanguage,
      sessionDate: sessionDate, // This will now be a Timestamp
      createdAt: editingSessionId ? originalData?.createdAt : Timestamp.now(),
      status: "created",
    };

    try {
      if (editingSessionId) {
        // Update the existing document with the sessionData
        const sessionRef = doc(db, "trainingSessions", editingSessionId);
        await updateDoc(sessionRef, { ...sessionData, sessionDate });
      } else {
        // Create a new document with the sessionData
        const docRef = await addDoc(
          collection(db, "trainingSessions"),
          sessionData
        );
        setDocumentId(docRef.id);
      }
      // Reset form data and state
      setFormData({ name: "", sessionDate: "", showResult: false });
      setSelectedLanguage("");
      setEditingSessionId(null);
      setOriginalData(null);
    } catch (error) {
      console.error("Error updating or adding document: ", error);
    }
  };

  const handleToggleStatus = async (
    sessionId: string,
    currentStatus: string
  ) => {
    const newStatus = currentStatus === "created" ? "completed" : "created";
    const sessionRef = doc(db, "trainingSessions", sessionId);
    try {
      await updateDoc(sessionRef, { status: newStatus });
    } catch (error) {
      console.error("Error updating document status: ", error);
    }
  };

  const handleEdit = (session: Session) => {
    // This value should be in 'YYYY-MM-DD' format for the input field.
    let sessionDateValue: string;
    if (session.sessionDate instanceof Timestamp) {
      sessionDateValue = session.sessionDate
        .toDate()
        .toISOString()
        .substring(0, 10);
    } else {
      // If sessionDate is already a string, we assume it's in the correct format
      sessionDateValue = session.sessionDate;
    }

    // Set the formData for the inputs, and originalData for comparison checks.
    setFormData({
      name: session.name,
      sessionDate: sessionDateValue,
      showResult: session.showResult || false,
    });
    setEditingSessionId(session.id);
    setOriginalData(session);
  };

  const handleCancel = () => {
    if (hasFormChanged()) {
      // Check for changes
      const confirmCancel = window.confirm(
        "You have unsaved changes. Are you sure you want to discard them?"
      );
      if (!confirmCancel) {
        return; // If user cancels, do nothing
      }
    }
    // Clear the form and switch to Create mode
    setFormData({ name: "", sessionDate: "", showResult: false });
    setEditingSessionId(null);
    setOriginalData(null);
  };

  const handleDelete = async (id: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this session? This action is irreversible."
    );
    if (!confirmDelete) {
      return; // If user cancels, do nothing
    }

    const sessionRef = doc(db, "trainingSessions", id);
    try {
      await updateDoc(sessionRef, {
        status: "deleted", // Set the status to 'deleted' instead of removing the document
      });
    } catch (error) {
      console.error("Error updating document status to 'deleted': ", error);
    }
  };

  const handleShowResultChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const isChecked = e.target.checked;
  
    // Update local state if necessary
    setFormData(prevState => ({
      ...prevState,
      showResult: isChecked,
    }));
  
    // Check if editingSessionId is not null and then update Firestore
    if (editingSessionId) {
      const sessionRef = doc(db, "trainingSessions", editingSessionId);
      try {
        await updateDoc(sessionRef, {
          showResult: isChecked,
        });
        console.log('Session showResult updated successfully');
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  return (
    <div className="mainContainer flex-gap-column">
      <div className="form">
        <h1>
          {editingSessionId
            ? "Edit Training Session"
            : "Create Training Session"}
        </h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Session Name"
            required
          />
          <input
            type="date"
            name="sessionDate"
            value={formData.sessionDate}
            onChange={handleChange}
            required
          />
          <select
            name="language"
            value={selectedLanguage || ""} // If selectedLanguage is null, default to the placeholder option
            onChange={(e) => {
              setSelectedLanguage(e.target.value);
              handleChange(e); // You may need to modify handleChange to update formData.language
            }}
          >
            <option value="">No additional language (optional)</option>
            {languages.map((language) => (
              <option key={language.id} value={language.id}>
                {language.name}
              </option>
            ))}
          </select>
          {editingSessionId && (
            <>
              <div
                className="switch-container show-mbti-results-container"
              >
                <div>Show MBTI Result</div>
                <div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={formData.showResult}
                      onChange={handleShowResultChange} />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <p>QR Code for the Training Session:</p>
                <QRCode
                  value={`${AppConfig.baseUrl}/student/${editingSessionId}`}
                  style={{ width: '50%', maxWidth: 360, height: 'auto' }} />
                <p style={{ fontSize: "smaller" }}>
                  URL:{" "}
                  <a
                    href={`${AppConfig.baseUrl}/student/${editingSessionId}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >{`${AppConfig.baseUrl}/student/${editingSessionId}`}</a>
                </p>
              </div>
            </>
          )}
          {
            editingSessionId && sessionResults.length > 0 && (
              <div style={{ padding: '1.5rem', textAlign: 'center' }}>
                <Link to={`/admin/results/${editingSessionId}`}>View Student Results</Link>
              </div>
            )
          }
          <div className="flex-gap">
            <button type="submit">
              {editingSessionId ? "Update" : "Create"}
            </button>
            {editingSessionId && (
              <button type="button" onClick={handleCancel}>
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>
      {!editingSessionId && (
        <div className="trainingSessionsList">
          {loading ? (
            <p>Loading...</p>
          ) : sessions.length > 0 ? (
            <>
              <h2>Training Sessions</h2>
              {sessions
                .sort((a, b) => {
                  // Convert Timestamp to Date object if necessary before comparing
                  const dateA =
                    a.sessionDate instanceof Timestamp
                      ? a.sessionDate.toDate()
                      : new Date(a.sessionDate);
                  const dateB =
                    b.sessionDate instanceof Timestamp
                      ? b.sessionDate.toDate()
                      : new Date(b.sessionDate);
                  return dateB.getTime() - dateA.getTime();
                }) // Sorted in descending order
                .map((session) => (
                  <div key={session.id} className="trainingSessionsItem">
                    <div style={{ padding: 10 }}>
                      <div style={{ fontWeight: "bold" }}>{session.name}</div>
                      <div style={{ fontSize: "smaller" }}>
                        {session.sessionDate && formatDate(session.sessionDate)}
                      </div>
                    </div>
                    <div className="trainingSessionsItemOptionsContainer">
                      <div
                        className="switch-container"
                        onClick={() =>
                          handleToggleStatus(session.id, session.status)
                        }
                        style={{ padding: 10, cursor: "pointer" }} // Make the entire container clickable
                      >
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={session.status === "completed"}
                            onChange={(e) => e.stopPropagation()} // Prevent checkbox click from bubbling up
                          />
                          <span className="slider"></span>
                        </label>
                        <span className="switch-label">Complete</span>
                      </div>
                      <div
                        className="session-actions"
                        style={{
                          padding: 10,
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          onClick={() => handleEdit(session)}
                          title="Edit"
                          className="icon-button" // Add a custom class for styling
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => handleDelete(session.id)}
                          title="Delete"
                          className="icon-button" // Add a custom class for styling
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </>
          ) : (
            <p>No Training sessions found</p>
          )}
        </div>
      )}
      {!editingSessionId && (
        <ListEnd />
      )}
    </div>
  );
};

export default TrainingSession;
