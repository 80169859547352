// src/components/StudentDataForm.tsx
import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Question, Scores } from '../types/types';
import { serverTimestamp } from 'firebase/firestore';
import { getAuth, signInAnonymously } from "firebase/auth";

const initialScores: Scores = {
  E: 0,
  I: 0,
  S: 0,
  N: 0,
  T: 0,
  F: 0,
  J: 0,
  P: 0,
};

const StudentDataForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
  });
  const [scores, setScores] = useState<Scores>(initialScores);
  const [mbtiType, setMbtiType] = useState('');
  const [questions, setQuestions] = useState<Question[]>([]);
  const [answers, setAnswers] = useState<(0 | 1 | 2 | null)[]>([]);
  // const [formTranslations, setFormTranslations] = useState<string[]>([]);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Sign-in student anonymously
  useEffect(() => {
    const auth = getAuth();
    const signInOrRetry = async () => {
      try {
        if (!auth.currentUser) {
          await signInAnonymously(auth);
          setIsSignedIn(true);
        } else {
          setIsSignedIn(true);
        }
      } catch (error) {
        console.error('Error signing in anonymously:', error);
        alert('Failed to sign in. Please check your network connection and try again.');
        // Optionally, implement a retry mechanism or further user instructions here
      }
    };
  
    signInOrRetry();
  }, []);
  /* useEffect(() => {
    const auth = getAuth();
    if (!auth.currentUser) {
      signInAnonymously(auth)
        .then(() => {
          // User is signed in anonymously
          setIsSignedIn(true);
        })
        .catch((error) => {
          console.error('Error signing in anonymously:', error);
        });
    } else {
      // User is already signed in
      setIsSignedIn(true);
    }
  }, []); */

  // Fetch translations for the form
  useEffect(() => {
    const loadFormTranslations = async () => {
      const sessionHashKey = localStorage.getItem('sessionHashKey');
      if (sessionHashKey) {
        const sessionDocRef = doc(db, 'trainingSessions', sessionHashKey);
        const sessionDocSnap = await getDoc(sessionDocRef);
        if (sessionDocSnap.exists()) {
          const sessionData = sessionDocSnap.data();
          const languageCode = sessionData.language;
          if (languageCode && languageCode !== 'en') {
            const translationsRef = doc(db, 'translatedUI', languageCode);
            const translationsSnap = await getDoc(translationsRef);
            /* if (translationsSnap.exists()) {
              setFormTranslations(translationsSnap.data().contactForm);
            } else {
              console.error(`No form translations found for language: ${languageCode}`);
            } */
          }
        } else {
          console.error('No session document found');
        }
      }
    };
    loadFormTranslations();
  }, []);

  useEffect(() => {
    // Retrieve the sessionHashKey from localStorage or from a prop/state if passed from the previous page
    const sessionHashKey = localStorage.getItem('sessionHashKey');
  
    if (sessionHashKey) {
      const sessionData = JSON.parse(localStorage.getItem('sessionData') || '{}');
      const sessionInfo = sessionData[sessionHashKey];
  
      if (sessionInfo) {
        const { studentQuestions, studentAnswers } = sessionInfo;
  
        if (studentQuestions && studentAnswers) {
          setQuestions(studentQuestions);
          setAnswers(studentAnswers);
        }
      } else {
        console.error('No session information found for the provided session key.');
        // Handle the case where the session info isn't found
      }
    } else {
      console.error('Session hash key is missing.');
      // Handle the missing sessionHashKey appropriately
    }
  }, []);
  
  useEffect(() => {
    // Use a local variable to calculate the new scores
    const newScores: Scores = { ...initialScores };
    questions.forEach((question, index) => {
      const answerValue = answers[index];
      if (answerValue !== null) {
        const scoreType = question.type_id as keyof Scores;
        newScores[scoreType] += answerValue;
      }
    });
  
    // Calculate the MBTI type based on the new scores
    const newMbtiType = `${newScores.E > newScores.I ? 'E' : 'I'}${
      newScores.S > newScores.N ? 'S' : 'N'
    }${newScores.T > newScores.F ? 'T' : 'F'}${
      newScores.J > newScores.P ? 'J' : 'P'
    }`;
  
    // Update the state only once at the end of the useEffect
    setScores(newScores);
    setMbtiType(newMbtiType);
  
    // console.log(JSON.stringify({ newScores }));
    // console.log(JSON.stringify({ newMbtiType }));
  }, [questions, answers]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isSubmitting || !isSignedIn) {
      return;
    }

    setIsSubmitting(true);
    setErrorMessage(''); // Clear previous error messages

    const sessionHashKey = localStorage.getItem('sessionHashKey');
    if (!sessionHashKey) {
      console.error('Session hash key is missing.');
      setErrorMessage('Session hash key is missing. Please start the process again.');
      setIsSubmitting(false);
      return;
    }

    // Set a timeout for the submission process
    const timeoutId = setTimeout(() => {
      if (isSubmitting) { // Check if we're still submitting
        console.error('Submission timeout.');
        setErrorMessage('Submission timed out. Please check your connection and try again.');
        setIsSubmitting(false); // Allow retry
      }
    }, 30000); // 30 seconds timeout

    try {
      const docRef = await addDoc(collection(db, 'results'), {
        ...formData,
        scores,
        mbtiType,
        sessionKey: sessionHashKey,
        createdAt: serverTimestamp(),
      });
      console.log('Document written with ID: ', docRef.id);
      clearTimeout(timeoutId); // Clear the timeout upon successful submission
      localStorage.setItem('documentId', docRef.id);
      navigate('/results');
    } catch (error: any) {
      console.error('Error adding document: ', error);
      let userFriendlyMessage = 'An error occurred while submitting your data.';
      if (error.code === 'unavailable' && error.message.includes('offline')) {
        userFriendlyMessage += ' It seems you are offline. Please check your connection and try again.';
      }
      setErrorMessage(userFriendlyMessage);
      clearTimeout(timeoutId); // Clear the timeout upon error
    } finally {
      setIsSubmitting(false); // Ensure we reset the submitting state
    }
  };


  return (
    <div className='mainContainer'>
      <div className='form' style={{ marginTop: '10%', padding: '0 20px' }}>
        <h1>
          Thank you for completing the questions
          {/* {formTranslations[0] && (
            <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}><br/>{formTranslations[0]}</span>
          )} */}
        </h1>
        <div style={{ marginTop: 20, marginBottom: 30 }}>
          Please fill in your details, and we can reveal your MBTI type and send it to you.
          {/* {formTranslations[1] && <p style={{ fontSize: 'smaller '}}>{formTranslations[1]}</p>} */}
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            // placeholder={"First Name " + formTranslations[2] || "First Name"}
            placeholder={"First Name"}
            required
          />
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            // placeholder={"Last Name " + formTranslations[3] || "Last Name"}
            placeholder={"Last Name"}
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            // placeholder={"Email Address " + formTranslations[4] || "Email Address"}
            placeholder={"Email Address"}
            required
          />
          <input
            type="tel"
            name="mobilePhone"
            value={formData.mobilePhone}
            onChange={handleChange}
            placeholder={"Mobile Phone "}
            required
          />
          <button type="submit" disabled={!isSignedIn || isSubmitting} style={{ marginTop: 30 }}>
            {/* {isSubmitting ? 'Submitting...' : `Let's go! ${formTranslations[6] || ''}`} */}
            {isSubmitting ? 'Submitting...' : `Let's go!`}
          </button>
          {errorMessage && (
            <div className='errorMessage' style={{ marginTop: '10px', marginBottom: '10px' }}>
              {errorMessage}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default StudentDataForm;
