import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import StudentLandingPage from './StudentLandingPage';
import { validateHashKey } from '../utils/validateHashKey';

const StudentEntry = () => {
  const { hashKey } = useParams();
  const [isValidKey, setIsValidKey] = useState<boolean | null>(null); // null indicates validation not yet performed

  useEffect(() => {
    const validateAndStoreKey = async () => {
      if (hashKey) {
        const isValid = await validateHashKey(hashKey);
        setIsValidKey(isValid);
        if (isValid) {
          // Existing logic: setting individual items
          localStorage.setItem('sessionHashKey', hashKey);

          // New logic: storing session data grouped by sessionHashKey
          const sessionData = JSON.parse(localStorage.getItem('sessionData') || '{}');
          
          // Initialize session data for this hashKey if it does not exist
          if (!sessionData[hashKey]) {
            sessionData[hashKey] = {
              studentQuestions: JSON.parse(localStorage.getItem('studentQuestions') || '[]'),
              studentAnswers: JSON.parse(localStorage.getItem('studentAnswers') || '[]'),
              currentQuestionIndex: JSON.parse(localStorage.getItem('currentQuestionIndex') || '0'),
            };
          }
          
          // Save the updated session data back to localStorage
          localStorage.setItem('sessionData', JSON.stringify(sessionData));
        }
      }
    };

    validateAndStoreKey();
  }, [hashKey]);

  if (isValidKey === null) {
    return <div className='mainContainer'>Validating...</div>; // Or some loading indicator
  }

  return isValidKey ? (
    <StudentLandingPage />
  ) : (
    <Navigate to="/" replace />
  );
};

export default StudentEntry;