// src/firebase-config.ts
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC3jgzy5KGO2udEwf9PeanY28peA6rJPMc",
  authDomain: "myprofiletype.firebaseapp.com",
  projectId: "myprofiletype",
  storageBucket: "myprofiletype.appspot.com",
  messagingSenderId: "1038890352906",
  appId: "1:1038890352906:web:389bd7e756972a514d3af3",
  measurementId: "G-YW15L8FR7M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { analytics, app, auth, db };